import { type CraftContentBlockBase, CraftContentBlockType } from "~/types/api/craft/fields/content-blocks"

/**
 * The styles of Craft text content blocks.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export enum CraftTextContentBlockStyle {
	Highlight = 1,

	Green = 2,
	Amber = 3,
	Red = 4
}

/**
 * Represents a Craft text content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftTextContentBlock extends CraftContentBlockBase {
	type: CraftContentBlockType.Text

	/**
	 * The raw HTML content of this text block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	html: string | null

	/**
	 * The style to render this text block as.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	style: CraftTextContentBlockStyle | null
}

/**
 * Ensures that a value is a Craft text content block.W
 * @param value The value to check.
 * @returns Whether the value is a Craft text content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftTextContentBlock = (value: unknown): value is CraftTextContentBlock =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftContentBlockType.Text.valueOf()
