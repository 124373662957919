import { useCallback, useMemo, type ComponentPropsWithoutRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import Metadata from "~/components/metadata"
import { useMediaQueries } from "~/hooks/use-media-query"
import { useNavigateBack } from "~/hooks/use-navigate-back"
import { useRouterState } from "~/hooks/use-router-state"
import { useCraftSiteSelection } from "~/hooks/use-selections"
import type { PDFViewerState } from "~/types/state"

import ChevronIcon from "~/assets/icons/chevron.svg?react"
import HANDiLogo from "~/assets/logos/handi.svg?react"

/**
 * The page for viewing a PDF file.
 * @returns The React component. This should only be used by the router.
 * @example <PDFViewerPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.6.0
 */
const PDFViewerPage = ({ ...props }: ComponentPropsWithoutRef<"main">): JSX.Element => {
	const { isLandscape } = useMediaQueries()

	const navigate = useNavigate()
	const { onClick: onBackClick } = useNavigateBack<SVGSVGElement>()

	const craftSite = useCraftSiteSelection()

	const routerState = useRouterState<PDFViewerState>()
	const [queryParameters] = useSearchParams()

	// Navigates to wherever index sends us when the logo is clicked...
	const onLogoClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
		if (craftSite === null) {
			console.warn("No site selection yet?!")
			return
		}

		navigate(craftSite.url)
	}, [navigate, craftSite])

	const [fileUrl, fileName] = useMemo<[URL | null, string | null]>(() => {
		const passedUrl = routerState?.url ?? queryParameters.get("url")
		if (passedUrl === null) return [null, null]

		const _fileUrl = new URL(passedUrl)
		const _fileName = _fileUrl.pathname.split("/").pop() ?? null

		return [_fileUrl, _fileName]
	}, [routerState, queryParameters])

	if (fileUrl === null) throw new Error("The PDF viewer page requires a PDF URL!")

	return (
		<>
			<Metadata title={fileName ?? "Unknown File Name"} shouldIndex={false} path={fileUrl.toString()} />

			<header className="flex flex-row items-center justify-between p-3">
				<ChevronIcon
					className="aspect-square rounded-xl fill-logo-purple p-3 ps-2 duration-hover hover:cursor-pointer hover:bg-control-hover active:bg-control-active"
					width={48}
					height={48}
					onClick={onBackClick}
				/>

				<HANDiLogo
					width={52}
					height={52}
					className="aspect-square hover:cursor-pointer"
					onClick={onLogoClick}
				/>

				{/* Only exists for equal spacing */}
				<ChevronIcon
					className="invisible aspect-square rotate-180 fill-logo-purple p-3 pe-2"
					width={48}
					height={48}
				/>
			</header>

			<main
				{...props}
				className={`flex flex-grow ${isLandscape === true ? "flex-row justify-between gap-x-4" : "flex-col"} overflow-auto ${props.className ?? ""}`.trimEnd()}
				style={{
					// @ts-expect-error TypeScript doesn't know about this CSS property
					// eslint-disable-next-line @typescript-eslint/naming-convention
					"-webkit-overflow-scrolling": "touch"
				}}>
				<object data={fileUrl.toString()} type="application/pdf" className="h-[10000px] w-full">
					<div className="flex flex-grow flex-col items-center justify-center gap-y-4 p-4 text-center">
						<h1 className="text-2xl font-bold">Unable to render the PDF file!</h1>
						<a
							href={fileUrl.toString()}
							download={fileName ?? ""}
							target="_blank"
							rel="noopener noreferrer">
							Download <code>{fileName}</code> instead.
						</a>
					</div>
				</object>
			</main>
		</>
	)
}

export default PDFViewerPage
