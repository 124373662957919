import { AnimatePresence } from "framer-motion"
import { useMemo, type ComponentPropsWithRef, type JSX } from "react"
import { Outlet, useLocation } from "react-router-dom"

import Header from "~/components/layout/header"
import { useRouteParameters } from "~/hooks/router/use-route-parameters"
import { Routes } from "~/router/routes"

/**
 * The standard base layout for the application.
 * This is required as a separate component instead of being in <App /> so the settings page can be rendered without the header, but still inherit from the <App /> component in the router.
 * @returns The React component.
 * @example <Layout />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const Layout = ({
	isErrorPage = false,

	children,
	...props
}: ComponentPropsWithRef<"div"> & {
	isErrorPage?: boolean
}): JSX.Element => {
	const { pathname } = useLocation()
	const { craftSiteHandleParameter, craftSectionHandleParameter, craftEntrySlugParameter } = useRouteParameters()

	// Whether we're on the consent mode page...
	const isConsentModePage = useMemo<boolean>(() => pathname === Routes.ConsentMode.valueOf(), [pathname])

	// Whether we're on the select trust page...
	const isSelectTrustPage = useMemo<boolean>(() => pathname === Routes.SelectTrust.valueOf(), [pathname])

	// Whether we're on the select role page...
	const isSelectRolePage = useMemo<boolean>(() => {
		if (craftSiteHandleParameter === null) return false
		return pathname === Routes.SelectRole.replace(":site", craftSiteHandleParameter)
	}, [craftSiteHandleParameter, pathname])

	// Whether we're on a Craft entry page...
	const isCraftEntryPage = useMemo<boolean>(() => {
		if (
			craftSiteHandleParameter === null ||
			craftSectionHandleParameter === null ||
			craftEntrySlugParameter === null
		)
			return false
		return (
			pathname ===
			Routes.CraftEntry.replace(":site", craftSiteHandleParameter)
				.replace(":role", craftSectionHandleParameter)
				.replace(":slug", craftEntrySlugParameter)
		)
	}, [craftSiteHandleParameter, craftSectionHandleParameter, craftEntrySlugParameter, pathname])

	return (
		<div
			{...props}
			className={`z-0 flex flex-grow flex-col justify-between gap-y-4 overflow-y-auto ${props.className ?? ""}`.trimEnd()}>
			<Header
				showBackButton={!isSelectTrustPage}
				showSettingsButton={!isSelectTrustPage}
				showSearch={isSelectRolePage}
				showBreadcrumbs={isCraftEntryPage}
				// showGrab={isCraftEntryPage}
				shouldRedirect={!(isErrorPage || isConsentModePage)}
			/>

			<AnimatePresence mode="wait">
				{children ?? <Outlet />} {/* This is <main> */}
			</AnimatePresence>
		</div>
	)
}

export default Layout
