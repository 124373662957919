// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL
if (API_BASE_URL === undefined || API_BASE_URL === "") throw new Error("The API base URL is missing!")

/**
 * The routes handled by the client-side router.
 * These mimic the routes in the former HANDi Paediatrics app.
 * @example <Link to={Routes.Index}>Home</Link>
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export enum Routes {
	Index = "/",

	ConsentMode = "/consent",

	SelectTrust = "/select",
	SelectRole = "/:site/home",
	CraftEntry = "/:site/:role/:slug",

	Settings = "/settings",

	PDFViewer = "/pdf"
}

/**
 * Constructs the URL to the Craft admin page for the given entry.
 * @param siteHandle The handle of the site (NHS trust).
 * @param sectionHandle The handle of the section (role).
 * @param entryId The ID of the entry.
 * @param entrySlug The slug of the entry.
 * @returns The URL to the Craft admin page.
 * @example const url = useCraftAdmin() // https://cms.handi.local/entries/professionals/9667-viral-wheeze-in-primary-care?site=bath
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const constructCraftAdminURL = (
	siteHandle: string,
	sectionHandle: string,
	entryId: number,
	entrySlug: string
): string =>
	`${new URL(API_BASE_URL).origin}/entries/${sectionHandle}/${entryId.toString()}-${entrySlug}?site=${siteHandle}`
