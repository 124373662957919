import { motion, type HTMLMotionProps } from "framer-motion"
import { useCallback, useEffect, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"

import BackgroundImage from "~/components/background-image"
import ContentCard from "~/components/content-card"
import InnerError from "~/components/inner-error"
import Metadata from "~/components/metadata"
import SkeletonLoader from "~/components/skeleton-loader"
import Button from "~/components/standard/button"
import { getConsentMode } from "~/helpers/consent-mode"
import { useCraftSites } from "~/hooks/craft/use-craft-sites"
import { useRouteCraftSite } from "~/hooks/router/use-route-craft-site"
import { useCraftSelectionsDispatch } from "~/hooks/use-selections"
import { BackgroundImagePath } from "~/images"
import { Routes } from "~/router/routes"
import type { CraftSite } from "~/types/api/craft/models/site"

import NHSLogo from "~/assets/logos/nhs.svg?react"

type ClickTrustCallback = (site: CraftSite) => void

const NHSPartnership = ({ ...props }: ComponentPropsWithRef<"div">): JSX.Element => (
	<div {...props} className={`flex flex-row items-center justify-center gap-x-2 ${props.className ?? ""}`.trimEnd()}>
		<p className="mt-1 text-nhs-blue">In partnership with</p> <NHSLogo className="fill-nhs-blue" width={64} />
	</div>
)

const TrustButton = ({
	site,
	isSelected = false,
	onClick,

	...props
}: Omit<ComponentPropsWithRef<typeof Button>, "label" | "onClick"> & {
	site: CraftSite
	isSelected?: boolean
	onClick?: ClickTrustCallback
}): JSX.Element => {
	const onInnerClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
		event => {
			event.preventDefault()
			onClick?.(site)
		},
		[onClick, site]
	)

	return (
		<Button
			{...props}
			label={site.name}
			onClick={onInnerClick}
			className={`text-1xl justify-center rounded-xl p-3 text-xl text-logo-purple duration-hover hover:bg-blue-400/10 hover:text-nhs-blue active:bg-blue-400/15 ${isSelected ? "bg-blue-400/5 font-bold" : ""} ${props.className ?? ""}`.trimEnd()}
		/>
	)
}

/**
 * The page for selecting an NHS trust.
 * This is the 1st step for new visitors.
 * @returns The React component. This should only be used by the router.
 * @example <SelectTrustPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const SelectTrustPage = ({ ...props }: HTMLMotionProps<"main">): JSX.Element => {
	const navigate = useNavigate()

	const craftSite = useRouteCraftSite()
	const { updateCraftSiteSelection } = useCraftSelectionsDispatch()

	const { sites, isReady, isError, error } = useCraftSites({})

	// Save trust & navigate to the select role page when a trust is selected...
	const onClick = useCallback<ClickTrustCallback>(
		site => {
			updateCraftSiteSelection(site)
			navigate(site.url)
		},
		[navigate, updateCraftSiteSelection]
	)

	// Require consent
	useEffect(() => {
		if (getConsentMode() === null) navigate(Routes.ConsentMode)
	}, [navigate])

	return (
		<motion.main
			{...props}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className={`flex flex-grow flex-col justify-end overflow-y-auto ${props.className ?? ""}`.trimEnd()}>
			{isError && error !== null ? (
				<InnerError
					heading="API Error"
					message="Sorry, we're having problems communicating with our server. Please try again later."
					backgroundImage={
						<BackgroundImage
							imageUrl={BackgroundImagePath.HandPaintingChild}
							accessibilityDescription="A child with paint on their hands."
						/>
					}
					error={error}
				/>
			) : (
				<>
					<Metadata title="Select Trust" path={Routes.SelectTrust} />

					{/* Feature */}
					<BackgroundImage
						imageUrl={BackgroundImagePath.HandPaintingChild}
						accessibilityDescription="A child with paint on their hands."
					/>

					{/* Card */}
					<ContentCard heading="Select your area">
						<NHSPartnership />

						{/* Trust list */}
						<SkeletonLoader
							rowCount={VITE_HANDI_SITE_COUNT} // Baked in at build time
							isLoading={!isReady || sites === null}
							className="overflow-y-scroll"
							innerClassName="gap-y-1 flex-col">
							{sites
								?.toSorted(({ name: a }, { name: b }) => a.localeCompare(b))
								.map(site => (
									<TrustButton
										key={site.id}
										site={site}
										isSelected={site.id === craftSite?.id}
										onClick={onClick}
									/>
								))}
						</SkeletonLoader>
					</ContentCard>
				</>
			)}
		</motion.main>
	)
}

export default SelectTrustPage
