import { createBrowserRouter } from "react-router-dom"

import App from "~/app"
import Layout from "~/components/layout/layout"
import ConsentModePage from "~/pages/consent-mode"
import CraftEntryPage from "~/pages/craft-entry"
import ErrorPage from "~/pages/error"
import IndexPage from "~/pages/index"
import PDFViewerPage from "~/pages/pdf-viewer"
import SelectRolePage from "~/pages/select-role"
import SelectTrustPage from "~/pages/select-trust"
import SettingsPage from "~/pages/settings"
import { Routes } from "~/router/routes"

/**
 * The router that handles client-side navigating between pages.
 * @see https://reactrouter.com/en/main/start/tutorial
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const router = createBrowserRouter([
	{
		path: Routes.Index,
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: Routes.Index,
				element: <Layout />,
				children: [
					{
						path: Routes.Index,
						element: <IndexPage />
					},
					{
						path: Routes.ConsentMode,
						element: <ConsentModePage />
					},
					{
						path: Routes.SelectTrust,
						element: <SelectTrustPage />
					},
					{
						path: Routes.SelectRole,
						element: <SelectRolePage />
					},
					{
						path: Routes.CraftEntry,
						element: <CraftEntryPage />
					}
				]
			},
			{
				path: Routes.Settings,
				element: <SettingsPage />
			},
			{
				path: Routes.PDFViewer,
				element: <PDFViewerPage />
			}
		]
	}
])
