import { type CraftContentBlockBase, CraftContentBlockType } from "~/types/api/craft/fields/content-blocks"
import type { CraftAsset } from "~/types/api/craft/models/asset"

/**
 * The sizes of a Craft single image content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export enum CraftSingleImageContentBlockSize {
	ThreeQuarters = 1, // 75%
	TwoThirds = 2, // 66%
	Half = 3 // 50%
}

/**
 * Converts a Craft single image content block size to a percentage.
 * @param size The size to convert.
 * @returns The percentage equivalent of the size.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const craftSingleImageContentBlockSizeToPercentage = (size: CraftSingleImageContentBlockSize | null): string => {
	// eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
	switch (size) {
		case CraftSingleImageContentBlockSize.ThreeQuarters:
			return "75%"
		case CraftSingleImageContentBlockSize.TwoThirds:
			return "66%"
		case CraftSingleImageContentBlockSize.Half:
			return "50%"
		default:
			return "100%"
	}
}

/**
 * Represents a Craft single image content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftSingleImageContentBlock extends CraftContentBlockBase {
	type: CraftContentBlockType.SingleImage

	/**
	 * The size of the asset.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	size: CraftSingleImageContentBlockSize | null

	/**
	 * The asset itself.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	asset: CraftAsset | null
}

/**
 * Ensures that a value is a Craft single image content block.
 * @param value The value to check.
 * @returns Whether the value is a Craft single image content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftSingleImageContentBlock = (value: unknown): value is CraftSingleImageContentBlock =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftContentBlockType.SingleImage.valueOf()
