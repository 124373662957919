import {
	useCallback,
	type ComponentPropsWithoutRef,
	type ComponentPropsWithRef,
	type JSX,
	type MouseEventHandler
} from "react"
import { useNavigate } from "react-router-dom"

import Footer from "~/components/layout/footer"
import Metadata from "~/components/metadata"
import Button from "~/components/standard/button"
import { IS_STAGING } from "~/constants"
import { useCraftAboutEntry } from "~/hooks/craft/use-craft-about-entry"
import { useCraftSymptomsEntry } from "~/hooks/craft/use-craft-symptoms-entry"
import { useMediaQueries } from "~/hooks/use-media-query"
import { useNavigateBack } from "~/hooks/use-navigate-back"
import { useCraftSectionSelection, useCraftSelectionsDispatch, useCraftSiteSelection } from "~/hooks/use-selections"
import { Routes } from "~/router/routes"
import type { CraftEntryState, SelectRoleState } from "~/types/state"

import ChevronIcon from "~/assets/icons/chevron.svg?react"
import InfoIcon from "~/assets/icons/info.svg?react"
import PractitionersIcon from "~/assets/icons/practitioners.svg?react"
import SearchIcon from "~/assets/icons/search.svg?react"
import SelectIcon from "~/assets/icons/select.svg?react"
import SymptomsIcon from "~/assets/icons/symptoms.svg?react"
import HANDiLogo from "~/assets/logos/handi.svg?react"

const SettingsButton = ({ ...props }: ComponentPropsWithRef<typeof Button>): JSX.Element => (
	<Button
		{...props}
		className={`gap-x-3 p-3.5 text-xl ${props.disabled !== true ? "hover:bg-logo-purple/10 active:bg-logo-purple/20" : ""} ${props.className ?? ""}`.trimEnd()}
	/>
)

const Section = ({
	heading,

	children,
	...props
}: ComponentPropsWithRef<"section"> & {
	heading: string
}): JSX.Element => (
	<section {...props} className={`flex flex-col ${props.className ?? ""}`.trimEnd()}>
		<div className="flex flex-row bg-logo-purple p-3 pb-2.5">
			<h2 className="text-2xl font-bold text-white">{heading}</h2>
		</div>

		<div className="flex flex-col">{children}</div>
	</section>
)

const Separator = ({ ...props }: ComponentPropsWithRef<"hr">): JSX.Element => (
	<hr {...props} className={`border-control-border ${props.className ?? ""}`.trimEnd()} />
)

/**
 * The page for modifying preferences & viewing additional information, etc.
 * @returns The React component. This should only be used by the router.
 * @example <SettingsPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const SettingsPage = ({ ...props }: ComponentPropsWithoutRef<"main">): JSX.Element => {
	const { isLandscape } = useMediaQueries()

	const navigate = useNavigate()
	const { onClick: onBackClick } = useNavigateBack<SVGSVGElement>()

	const craftSite = useCraftSiteSelection()
	const craftSection = useCraftSectionSelection()
	const { clearCraftSiteSelection, clearCraftSectionSelection } = useCraftSelectionsDispatch()

	const { entry: symptomsEntry } = useCraftSymptomsEntry({
		siteId: craftSite?.id ?? null,
		sectionId: craftSection?.id ?? null,
		skip: craftSection?.handle === "about" || craftSection?.handle === "home"
	})

	const { entry: aboutEntry } = useCraftAboutEntry({
		siteId: craftSite?.id ?? null
	})

	// Navigates to wherever index sends us when the logo is clicked...
	const onLogoClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
		if (craftSite === null) {
			console.warn("No site selection yet?!")
			return
		}

		navigate(craftSite.url)
	}, [navigate, craftSite])

	// Navigates to the symptoms page for the selected role & trust...
	const onSymptomsClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (craftSite === null) {
			console.warn("No Craft site selection yet?!")
			return
		}

		if (symptomsEntry === null) {
			console.warn("Could not find the symptoms Craft entry!")
			return
		}

		navigate(symptomsEntry.url, {
			state: {
				target: {
					site: craftSite.id,
					section: symptomsEntry.section,
					entry: symptomsEntry.id,
					url: symptomsEntry.url
				}
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, craftSite, symptomsEntry])

	// Navigates to the select role page which has a search bar...
	const onSearchClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (craftSite === null) {
			console.warn("No site selection yet?!")
			return
		}

		navigate(craftSite.url, {
			state: {
				shouldFocusSearchBar: true
			} satisfies SelectRoleState as SelectRoleState
		})
	}, [navigate, craftSite])

	// Navigates to the trust's about entry...
	const onAboutClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (craftSite === null) {
			console.warn("No Craft site selection yet?!")
			return
		}

		if (aboutEntry === null) {
			console.warn("Could not find the About Craft CMS entry!")
			return
		}

		// Special case where about is a "role"
		navigate(aboutEntry.url, {
			state: {
				target: {
					site: craftSite.id,
					section: aboutEntry.section,
					entry: aboutEntry.id,
					url: aboutEntry.url
				}
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, craftSite, aboutEntry])

	// Navigates to the select trust page...
	const onSelectTrustClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		clearCraftSiteSelection()
		navigate(Routes.SelectTrust)
	}, [navigate, clearCraftSiteSelection])

	// Navigates to the select role page...
	const onSelectRoleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (craftSite === null) {
			console.warn("No site selection yet?!")
			return
		}

		clearCraftSectionSelection()
		navigate(craftSite.url)
	}, [navigate, clearCraftSectionSelection, craftSite])

	/*
	// TODO: Navigate to the contact page...
	const onContactClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		navigate(Routes.Index)
	}, [navigate])

	// TODO: Navigate to the changelog page...
	const onChangelogClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		navigate(Routes.Index)
	}, [navigate])
	*/

	// TODO: Navigate to the Terms & Conditions page...
	// const onTermsConditionsClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
	// 	navigate(Routes.Index)
	// }, [navigate])

	return (
		<>
			<Metadata title="Settings" path={Routes.Settings} />

			<header className="flex flex-row items-center justify-between p-6">
				{/* Navigate back button */}
				<ChevronIcon
					className="aspect-square rounded-xl fill-logo-purple p-3 ps-2 duration-hover hover:cursor-pointer hover:bg-control-hover active:bg-control-active"
					width={48}
					height={48}
					onClick={onBackClick}
				/>

				<HANDiLogo
					width={72}
					height={72}
					className="aspect-square hover:cursor-pointer"
					onClick={onLogoClick}
				/>

				{/* Only exists for equal spacing */}
				<ChevronIcon
					className="invisible aspect-square rotate-180 fill-logo-purple p-3 pe-2"
					width={48}
					height={48}
				/>
			</header>

			<main
				{...props}
				className={`flex flex-grow ${isLandscape === true ? "flex-row justify-between gap-x-4" : "flex-col"} ${props.className ?? ""}`.trimEnd()}>
				<Section heading="Your Trust" className={isLandscape === true ? "flex-grow" : ""}>
					<SettingsButton
						label="Symptoms"
						icon={<SymptomsIcon className="aspect-square" width={24} height={24} />}
						disabled={symptomsEntry === null}
						onClick={onSymptomsClick}
					/>
					<Separator />
					<SettingsButton
						label="Search"
						icon={<SearchIcon className="aspect-square" width={24} height={24} />}
						disabled={craftSite === null}
						onClick={onSearchClick}
					/>
					<Separator />
					<SettingsButton
						label={`About ${craftSite?.name ?? ""}`.trimEnd()}
						icon={<InfoIcon className="aspect-square" width={24} height={24} />}
						disabled={craftSite === null}
						onClick={onAboutClick}
					/>
					{/* <SettingsButton
						label="About"
						icon={<InfoIcon className="aspect-square" width={24} height={24} />}
						disabled={aboutPartialEntry === null}
						onClick={onAboutClick}
					/> */}
				</Section>

				<Section heading="Settings" className={isLandscape === true ? "flex-grow" : ""}>
					<SettingsButton
						label="Select NHS trust"
						icon={<SelectIcon className="aspect-square" width={24} height={24} />}
						onClick={onSelectTrustClick}
					/>
					<Separator />
					<SettingsButton
						label="Select your role"
						icon={<PractitionersIcon className="aspect-square" width={24} height={24} />}
						disabled={craftSite === null}
						onClick={onSelectRoleClick}
					/>
				</Section>

				{/* <Section heading="Legal">
					<SettingsButton
						label="Terms & Conditions"
						icon={<BookIcon className="aspect-square" width={24} height={24} />}
						onClick={onTermsConditionsClick}
					/>
				</Section> */}

				{/* <Section heading="Support" className={isLandscape === true ? "flex-grow" : ""}>
					<SettingsButton
						label="Contact us"
						icon={<ContactIcon className="aspect-square" width={24} height={24} />}
						disabled={true}
						onClick={onContactClick}
					/>
					<Separator />
					<SettingsButton
						label="Changelog"
						icon={<BookIcon className="aspect-square" width={24} height={24} />}
						disabled={true}
						onClick={onChangelogClick}
					/>
				</Section> */}
			</main>

			{/* Show some debugging information during local development or when running on the staging environment */}
			{(import.meta.env.DEV || IS_STAGING) && <Footer />}
		</>
	)
}

export default SettingsPage
