import { type CraftContentBlockBase, CraftContentBlockType } from "~/types/api/craft/fields/content-blocks"
import type { CraftLink } from "~/types/api/craft/link"

/**
 * Represents a Craft choice content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftChoiceContentBlock extends CraftContentBlockBase {
	type: CraftContentBlockType.Choice

	/**
	 * The question to ask.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	question: string | null

	/**
	 * The action for the positive choice.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	positive: CraftLink | null

	/**
	 * The action for the negative choice.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	negative: CraftLink | null
}

/**
 * Ensures that a value is a Craft choice content block.
 * @param value The value to check.
 * @returns Whether the value is a Craft choice content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftChoiceContentBlock = (value: unknown): value is CraftChoiceContentBlock =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftContentBlockType.Choice.valueOf()
