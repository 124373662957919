import { motion, type HTMLMotionProps } from "framer-motion"
import { useEffect, useState, type JSX } from "react"
import { Outlet, useLocation } from "react-router-dom"

import { Routes } from "~/router/routes"

const LIGHT_THEME_PAGES = [Routes.Settings.valueOf(), Routes.PDFViewer.valueOf()]

/**
 * The primary component for the application.
 * This component will render client-side routed pages as children.
 * This is injected into the 'root' HTML div element.
 * @returns The primary component.
 * @example <App />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const App = ({ children, ...props }: HTMLMotionProps<"div">): JSX.Element => {
	// const { isMobile, isLandscape } = useMediaQueries()

	// Settings & PDF viewer is purple text on white background, all other pages are white text on purple background...
	const { pathname } = useLocation()
	const [themeStyle, setThemeStyle] = useState<string>("bg-logo-purple text-white")
	useEffect(() => {
		const isLightThemePage = LIGHT_THEME_PAGES.includes(pathname)
		setThemeStyle(isLightThemePage ? "bg-white text-logo-purple" : "bg-logo-purple text-white")
	}, [pathname])

	// Show a temporary message if the app is not running on a mobile device...
	// if (isMobile === false && isLandscape === false)
	// 	return (
	// 		<main className="mx-4 flex h-dvh flex-col items-center justify-center gap-y-4">
	// 			<img src={LogoPath.Transparent} alt="The HANDi Paediatrics logo." width={128} height={128} />
	// 			<p className="text-center font-bold text-logo-purple">
	// 				Sorry, the HANDi Paediatrics app is only available on mobile for now.
	// 			</p>
	// 			<div className="flex flex-row gap-x-4">
	// 				{/* Android - https://partnermarketinghub.withgoogle.com/brands/google-play/visual-identity/badge-guidelines/ */}
	// 				<img
	// 					src="/images/stores/google/dark.webp"
	// 					alt="Download the app on the Google Play Store."
	// 					width={192}
	// 				/>

	// 				{/* iOS - https://developer.apple.com/app-store/marketing/guidelines/ */}
	// 				<img
	// 					src="/images/stores/apple/dark.svg"
	// 					alt="Download the app on the Apple App Store."
	// 					width={192}
	// 				/>
	// 			</div>
	// 		</main>
	// 	)

	return (
		<motion.div
			{...props}
			key={themeStyle}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			className={`flex h-dvh w-dvw flex-col overflow-hidden ${themeStyle} ${props.className ?? ""}`.trimEnd()}>
			{/* This is <header>, <main> & <footer> */}
			{children ?? <Outlet />}
		</motion.div>
	)
}

export default App
